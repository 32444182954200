import styled from 'styled-components';
import { Card } from '@blueprintjs/core';

const StyledCard = styled(Card).attrs(props => ({
    className: 'bp5-dark'
}))`
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    min-width: 300px;
`;

export default StyledCard;
