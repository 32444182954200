import { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { Button, Colors, Icon, Intent, Spinner, Tag, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { filter } from 'rxjs/operators';

import TagOverlay from './TagOverlay';

const copyrightWarning = 'This track appears to be copyrighted. Play at your own risk!'

const PlaylistsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    & > * {
        margin-right: 20px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

// min-width: 0; fixes long TrackTitles resizing the whole div. somehow.
const PlaylistOuter = styled.div`
    width: 100%;
    flex-grow: 1;
    min-width: 0;
`;
    
const PlaylistInner = styled.div.attrs(props => ({
    className: 'bp5-card'
}))`
    height: 500px;
    overflow-y: auto;
    ${props => !props.connected
        ? 'display: flex; justify-content: center; align-items: center;'
        : '&:after { content: ""; display: block; height: 20px; width: 100%; }'
    }

    & > * {
        margin-bottom: .4em;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

const PlaylistRow = styled.div`
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    opacity: ${props => props.isDragging ? '50%' : '100%'};
    ${props => props.isCurrent && `background-color: ${Colors.DARK_GRAY5};`}
`;

const Handle = styled.div`
    cursor: grab;
`;

const TrackTitle = styled.a`
    display: flex;
    ${props => props.hasCopyright && `color: ${Colors.RED5} !important;`}

    & > * {
        margin-right: .4em;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

const TagList = styled.div`
    display: flex;
    min-height: 20px;
    margin-top: .2em;
    ${props => !props.hasTags && '&:after { opacity: 50%; content: "Untagged"; font-style: oblique; }'}
    
    & > * {
        margin-left: .5em;
    }
    
    & > *:first-child {
        margin-left: 0;
    }
`;

const TitleAndTags = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    margin-right: 1em;
    text-align: left;
    min-width: 0;
`;

// NOTE: this will need to handle start points eventually
const asYoutubeUrl = (slug) => {
    return `https://www.youtube.com/watch?v=${slug}`;
};

// TODO: this can all be memoized to shit
const PlaylistItem = ({
    data,
    playlist,
    getCurrentPosition,
    moveCard,
    isCurrent,
    onClickPlay,
    onClickDelete,
    onClickSkip,
    onClickBan,
    onClickBanCurrent,
    onClickAddTag,
    onClickApprove,
}) => {
    const originalIndex = getCurrentPosition(data._id).index;
    const originalPlaylist = playlist;
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'PlaylistItem',
        item: () => {
            return { id: data._id, playlist, originalIndex };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        isDragging: (monitor) => {
            // by default isDragging will break if the item switches parent
            // redefining it like this fixes that
            return data._id === monitor.getItem().id;
        },
        end: (item, monitor) => {
            // if the item was dropped somewhere other than on a drop handler, put it back where it came from
            const { id: droppedId, originalIndex } = item;
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                moveCard(droppedId, originalPlaylist, originalIndex);
            }
        },
    });
    const [, drop] = useDrop({
        accept: 'PlaylistItem',
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== data._id) {
                const { index: overIndex, playlist: overPlaylist } = getCurrentPosition(data._id);
                moveCard(draggedId, overPlaylist, overIndex);
            }
        },
    });

    // NOTE: <Text> doesn't seem to check sizing more than once but it's mostly not an issue
    return (
        <PlaylistRow
            ref={(node) => preview(drop(node))}
            isDragging={isDragging}
            isCurrent={isCurrent}
        >
            <Handle ref={drag}>
                <Icon icon={IconNames.MOVE} iconSize={Icon.SIZE_LARGE} color={Colors.GRAY4} />
            </Handle>
            <TitleAndTags>
                <TrackTitle href={asYoutubeUrl(data.slug)} hasCopyright={data.hasCopyright}>
                    { data.hasCopyright && <Icon htmlTitle={copyrightWarning} icon={IconNames.WARNING_SIGN} intent={Intent.DANGER} /> }
                    <Text ellipsize>{data.title || 'No Title'}</Text>
                </TrackTitle>
                <TagList hasTags={data.tags?.length > 0}>{data.tags?.map((tag) => <Tag>{tag}</Tag>)}</TagList>
            </TitleAndTags>
            { isCurrent
                ? (
                    <Button minimal title="Skip" icon={IconNames.STEP_FORWARD} onClick={onClickSkip} />
                ) : (
                    <>
                        { data.approved
                            ? (
                                <Button
                                    minimal
                                    title="Reject"
                                    icon={IconNames.TICK}
                                    intent={Intent.SUCCESS}
                                    onClick={() => onClickApprove(false)}
                                />
                            )
                            : (
                                <Button
                                    minimal
                                    title="Approve"
                                    icon={IconNames.TICK}
                                    onClick={() => onClickApprove(true)}
                                />
                            )
                        }
                        <Button minimal title="Play" icon={IconNames.PLAY} onClick={onClickPlay} />
                        <Button minimal title="Delete" icon={IconNames.TRASH} onClick={onClickDelete} />
                    </>
                )
            }
            <Button
                minimal
                title="Ban"
                icon={IconNames.BAN_CIRCLE}
                onClick={isCurrent ? onClickBanCurrent : onClickBan}
            />
            <Button minimal title="Edit Tags" icon={IconNames.TAG} onClick={onClickAddTag} />
        </PlaylistRow>
    );
};

const Playlist = ({ websocket, connected }) => {
    // ordinarily i'd have these separate but state updates made inside react-dnd's hooks
    // don't seem to be batched, leading to some real fucked behaviour
    // so. these 2 are crammed into a single state variable.
    const [internalTracklists, setInternalTracklists] = useState({ active: [], hold: [] });
    const [currentTrackId, setCurrentTrackId] = useState();
    const [tagDialog, setTagDialog] = useState({ open: false });

    useEffect(() => {
        // TODO: probably gonna have to make this more complex to handle changes mid-drag better
        // i think it should be fine in such a case, just needs to cancel drag if the dragged item is removed
        if (!websocket) return;
        const playlist$ = websocket.message$.pipe(filter((message) => message.topic === 'playlists'));
        const subscription = playlist$.subscribe(({ body }) => {
            setInternalTracklists({
                active: body.activePlaylist,
                hold: body.holdPlaylist
            });
            if (body.currentTrackId !== undefined) setCurrentTrackId(body.currentTrackId);
        });

        // now we're connected, fetch the playlists
        websocket.send({ type: 'getPlaylists' })

        return () => {
            console.log('playlist$ unsubbed from websocket (reason: posted cringe :/)');
            subscription.unsubscribe();
        };
    }, [websocket]);

    // this is up here now so that the droppable area is the size of the playlist itself
    // rather than each item within it (ie. padding etc is included)
    const [, dropActive] = useDrop({
        accept: 'PlaylistItem',
        drop({ id: droppedId, playlist: originalPlaylist }) {
            const { index: overIndex } = getCurrentPosition(droppedId);
            handleDrop(droppedId, originalPlaylist, 'active', overIndex);
        },
        hover({ id: draggedId }) {
            // move the item over if the playlist has nothing in it
            // (the playlist items will handle it themselves otherwise)
            if (internalTracklists.active.length === 0) {
                moveCard(draggedId, 'active', 0);
            }
        },
    });
    const [, dropHold] = useDrop({
        accept: 'PlaylistItem',
        drop({ id: droppedId, playlist: originalPlaylist }) {
            const { index: overIndex } = getCurrentPosition(droppedId);
            handleDrop(droppedId, originalPlaylist, 'hold', overIndex);
        },
        hover({ id: draggedId }) {
            if (internalTracklists.hold.length === 0) {
                moveCard(draggedId, 'hold', 0);
            }
        },
    });

    // reposition playlist items
    const moveCard = (id, toPlaylist, atIndex) => {
        const { index, playlist } = getCurrentPosition(id);
        // TODO: cook up a common module so i can use the same code as in the backend here lol
        if (playlist === 'active' && toPlaylist === 'active') {
            const newActiveTracklist = JSON.parse(JSON.stringify(internalTracklists.active));
            newActiveTracklist.splice(atIndex, 0, newActiveTracklist.splice(index, 1)[0]);
            setInternalTracklists({ active: newActiveTracklist, hold: internalTracklists.hold });
        } else if (playlist === 'hold' && toPlaylist === 'hold') {
            const newHoldTracklist = JSON.parse(JSON.stringify(internalTracklists.hold));
            newHoldTracklist.splice(atIndex, 0, newHoldTracklist.splice(index, 1)[0]);
            setInternalTracklists({ active: internalTracklists.active, hold: newHoldTracklist });
        } else if (playlist === 'active' && toPlaylist === 'hold') {
            const newActiveTracklist = JSON.parse(JSON.stringify(internalTracklists.active));
            const newHoldTracklist = JSON.parse(JSON.stringify(internalTracklists.hold));
            newHoldTracklist.splice(atIndex, 0, newActiveTracklist.splice(index, 1)[0]);
            setInternalTracklists({ active: newActiveTracklist, hold: newHoldTracklist });
        } else {
            const newActiveTracklist = JSON.parse(JSON.stringify(internalTracklists.active));
            const newHoldTracklist = JSON.parse(JSON.stringify(internalTracklists.hold));
            newActiveTracklist.splice(atIndex, 0, newHoldTracklist.splice(index, 1)[0]);
            setInternalTracklists({ active: newActiveTracklist, hold: newHoldTracklist });
        }
    };

    const getCurrentPosition = (id) => {
        // TODO: this is some real dogshit code lol
        let playlist = 'active'
        let card = internalTracklists.active.filter((c) => c._id === id)[0];
        let index = internalTracklists.active.indexOf(card);
        if (!card) {
            playlist = 'hold';
            card = internalTracklists.hold.filter((c) => c._id === id)[0];
            index = internalTracklists.hold.indexOf(card);
        }
        return {
            index,
            playlist
        };
    };

    const handleDrop = (id, fromPlaylist, toPlaylist, newPosition) => {
        websocket.send({
            type: 'moveTrack',
            message: {
                trackId: id,
                fromPlaylist,
                toPlaylist,
                newPosition
            },
        });
    };

    const handleDelete = (id) => () => {
        websocket.send({
            type: 'removeTrack',
            message: id
        });
    };

    const handlePlay = (id) => () => {
        websocket.send({
            type: 'setCurrentTrack',
            message: id,
        });
    };

    const handleSkip = () => {
        websocket.send({
            type: 'skipTrack',
        });
    };

    const handleBan = (id) => () => {
        websocket.send({
            type: 'banTrack',
            message: id,
        });
    };

    const handleBanCurrent = () => {
        websocket.send({
            type: 'banCurrentTrack',
        });
    };

    const handleAddTag = (title, mediaId, tags) => () => {
        setTagDialog({ open: true, title, mediaId, tags: tags || [] });
    };

    const handleTagSubmit = (mediaId, tags) => {
        setTagDialog({ open: false, title: undefined, mediaId: undefined, tags: undefined });
        websocket.send({
            type: 'setTags',
            message: { mediaId, tags: tags.filter((t) => !!t) }
        });
    };

    const handleClose = () => {
        setTagDialog({ open: false, title: undefined, mediaId: undefined, tags: undefined });
    };

    const handleSetApproval = (trackId) => (shouldApprove) => {
        websocket.send({
            type: 'setTrackApproved',
            message: { trackId, approved: shouldApprove }
        });
    };

    return (
        <>
            <PlaylistsContainer>
                <PlaylistOuter>
                    <h5 className="bp5-heading">Active Queue</h5>
                    <PlaylistInner connected={connected} ref={dropActive}>
                        { connected ? internalTracklists.active.map((x, idx) => (
                            <PlaylistItem
                                key={x._id}
                                playlist={'active'}
                                data={x}
                                getCurrentPosition={getCurrentPosition}
                                moveCard={moveCard}
                                isCurrent={x._id === currentTrackId}
                                onClickPlay={handlePlay(x._id)}
                                onClickDelete={handleDelete(x._id)}
                                onClickSkip={handleSkip}
                                onClickBan={handleBan(x.mediaId)}
                                onClickBanCurrent={handleBanCurrent}
                                onClickAddTag={handleAddTag(x.title, x.mediaId, x.tags)}
                                onClickApprove={handleSetApproval(x.id)}
                            />
                        )) : <Spinner size={Spinner.SIZE_LARGE} />
                        }
                    </PlaylistInner>
                </PlaylistOuter>
                <PlaylistOuter>
                    <h5 className="bp5-heading">Hold Queue</h5>
                    <PlaylistInner connected={connected} ref={dropHold}>
                        { connected ? internalTracklists.hold.map((x, idx) => (
                            <PlaylistItem
                                key={x._id}
                                playlist={'hold'}
                                data={x}
                                getCurrentPosition={getCurrentPosition}
                                moveCard={moveCard}
                                isCurrent={x._id === currentTrackId}
                                onClickPlay={handlePlay(x._id)}
                                onClickDelete={handleDelete(x._id)}
                                onClickSkip={handleSkip}
                                onClickBan={handleBan(x.mediaId)}
                                onClickBanCurrent={handleBanCurrent}
                                onClickAddTag={handleAddTag(x.title, x.mediaId, x.tags)}
                                onClickApprove={handleSetApproval(x.id)}
                            />
                        )) : <Spinner size={Spinner.SIZE_LARGE} />
                        }
                    </PlaylistInner>
                </PlaylistOuter>
            </PlaylistsContainer>
            <TagOverlay
                show={tagDialog.open}
                title={tagDialog.title}
                mediaId={tagDialog.mediaId}
                tags={tagDialog.tags}
                onSubmit={handleTagSubmit}
                onClose={handleClose}
            />
        </>
    );
};

export default Playlist;
