import { useField } from 'formik'
import { Icon, InputGroup, Intent, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import styled from 'styled-components'

const IconContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
`;

const TextField = ({ type, placeholder, ...props}) => {
    const [field, meta] = useField(props.name);

    const errorWarning = (
        <Tooltip
            placement="right"
            content={(meta.error && meta.touched) ? meta.error : ':)'}
            intent={Intent.DANGER}
        >
            <IconContainer>
                <Icon icon={IconNames.WARNING_SIGN} intent={Intent.DANGER} />
            </IconContainer>
        </Tooltip>
    );

    return (
            <InputGroup
                {...field}
                placeholder={placeholder}
                type={type}
                intent={(meta.error && meta.touched) ? Intent.DANGER : undefined}
                rightElement={(meta.error && meta.touched) ? errorWarning : undefined}
            />
    );
};

export default TextField;
