import { useEffect, useState } from 'react';
import styled from 'styled-components';

const DeckTitleContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const DeckTitle = ({ deckId }) => {
    const [deckUsername, setDeckUsername] = useState();

    useEffect(() => {
        const getDeckUsername = async () => {
            // attempt to fetch user data
            const res = await fetch(`/api/user?id=${deckId}`, {
                method: 'GET',
                credentials: 'include',
            });
            console.log(res);
            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setDeckUsername(data.user.username);
            } else {
                setDeckUsername(undefined);
            }
        };

        getDeckUsername();
    }, [deckId]);

    return (
        <DeckTitleContainer>
            <h3 class="bp5-heading">{deckUsername}'s Deck</h3>
        </DeckTitleContainer>
    );
};

export default DeckTitle;
