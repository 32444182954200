import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { filter } from 'rxjs/operators';
import { Button, NonIdealState, Overlay, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import TextField from '../../common/fields';
import Card from '../../common/Card';

const ModalStates = Object.freeze({
    INITIAL: 'INITIAL',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
});

// taken from https://stackoverflow.com/questions/19377262/regex-for-youtube-url
// not perfect but, good enough for this
const youtubeUrlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

const MediaSchema = Yup.object().shape({
    url: Yup.string()
        .matches(youtubeUrlRegex, 'Invalid URL')
        .required('Required'),
});

const StyledForm = styled(Form)`
    width: 100%;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;

    &:disabled {
        opacity: 50%;
        cursor: default;
    }
`;

const NewMediaModal = ({ websocket, active, onClose }) => {
    const [status, setStatus] = useState(ModalStates.INITIAL);
    const [details, setDetails] = useState();

    useEffect(() => {
        if (!websocket) return;
        const newMediaStatus$ = websocket.message$.pipe(
            filter((message) => message.topic === 'settings' && message.subtopic === 'newMedia')
        );
        const subscription = newMediaStatus$.subscribe(({ body }) => {
            setStatus(body.status === 'success' ? ModalStates.SUCCESS : ModalStates.FAILURE);
            setDetails(body.details);
        });

        return () => {
            console.log('newMediaStatus$ unsubbing from websocket.message$');
            subscription.unsubscribe();
        };
    }, [websocket]);

    const addMedia = ({ url }) => {
        setStatus(ModalStates.PENDING);
        websocket.send({ type: 'addMedia', message: { slug: url.match(youtubeUrlRegex)[5] } });
    };

    const dismissButton = (
        <Button
            text={'Return'}
            onClick={() => {
                setStatus(ModalStates.INITIAL);
                setDetails(undefined);
                onClose();
            }}
        />
    );

    let CardContent;
    switch (status) {
        case ModalStates.INITIAL:
            CardContent = (
                <>
                    <h1 className="bp5-heading">Add New Media</h1>
                    <Formik
                        initialValues={{
                            url: '',
                        }}
                        validationSchema={MediaSchema}
                        onSubmit={(values) => addMedia(values)}
                    >
                        {({ dirty, isValid }) => (
                            <StyledForm>
                                <TextField
                                    name="url"
                                    placeholder="Media URL"
                                />
                                <StyledButton
                                    text="Submit"
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                />
                            </StyledForm>
                        )}
                    </Formik>
                </>
            );
            break;
        case ModalStates.PENDING:
            CardContent = <Spinner size={Spinner.SIZE_LARGE} />;
            break;
        case ModalStates.SUCCESS:
            CardContent = (
                <NonIdealState
                    icon={IconNames.TICK_CIRCLE}
                    title={'Media added successfully!'}
                    description={details}
                    action={dismissButton}
                />
            );
            break;
        case ModalStates.FAILURE:
            CardContent = (
                <NonIdealState
                    icon={IconNames.WARNING_SIGN}
                    title={'Unable to add media!'}
                    description={details}
                    action={dismissButton}
                />
            );
            break;
        default:
    }

    return (
        <Overlay
            isOpen={active}
            onClose={onClose}
        >
            <Card>
                {CardContent}
            </Card>
        </Overlay>
    );
};

export default NewMediaModal;
