import { useEffect, useState } from 'react';
import { ControlGroup, InputGroup, Button } from '@blueprintjs/core';
import styled from 'styled-components';

const StatusLine = styled.div`
    height: 1em;
`;

const ModeratorRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const ModeratorList = styled.div`
    margin-top: 10px;
`;

const Moderator = ({ id, username, onClickRemove }) => {
    return (
        <ModeratorRow>
            {username}
            <Button onClick={() => onClickRemove(id)}>Remove</Button>
        </ModeratorRow>
    );
};

const Moderators = () => {
    const [username, setUsername] = useState();
    const [status, setStatus] = useState();
    const [moderators, setModerators] = useState();

    useEffect(() => {
        const getModerators = async () => {
            // attempt to fetch user data
            const res = await fetch('/api/moderators', {
                method: 'GET',
                credentials: 'include',
            });
            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setModerators(data.users);
            } else {
                setModerators(undefined);
            }
        };

        getModerators();
    }, []);

    const handleModNameChange = (e) => {
        setStatus(undefined);
        setUsername(e.target.value);
    };

    const appointModerator = async () => {
        const res = await fetch('/api/addModerator', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username }),
            credentials: 'include',
        });
        const data = await res.json();
        console.log(data);
        if (data.message) {
            setStatus(data.message);
        }
        if (res.status === 200) {
            const newModerators = JSON.parse(JSON.stringify(moderators));
            newModerators.push(data.user);
            setModerators(newModerators);
        }
    };
    
    const onClickRemove = async (id) => {
        const res = await fetch('/api/removeModerator', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id }),
            credentials: 'include',
        });
        const data = await res.json();
        console.log(data);
        if (data.message) {
            setStatus(data.message);
        }
        if (res.status === 200) {
            const newModerators = JSON.parse(JSON.stringify(moderators));
            setModerators(newModerators.filter((u) => u.id !== id));
        }
    };

    return (
        <div>
            <h3 class="bp5-heading">Moderators</h3>
            <ControlGroup fill>
                <InputGroup
                    placeholder="Username"
                    onChange={handleModNameChange}
                />
                <Button
                    text="Add New"
                    onClick={() => appointModerator()}
                />
            </ControlGroup>
            <StatusLine>{status}</StatusLine>
            <ModeratorList>
                { moderators && moderators.map((m) => (
                    <Moderator
                        key={m.username}
                        id={m.id}
                        username={m.username}
                        onClickRemove={onClickRemove}
                    />
                )) }
            </ModeratorList>
        </div>
    );
};

export default Moderators;
