import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { filter } from 'rxjs/operators';
import { Button, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useParams } from 'react-router-dom';

import UserContext from '../../UserContext';
import NewMediaModal from './NewMediaModal';

const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    margin-right: 20px;
`;

const ControlsInner = styled.div.attrs(props => ({
    className: 'bp5-card'
}))`
    height: 270px;
    ${props => !props.connected && 'display: flex; justify-content: center; align-items: center;'}
`;

const Controls = ({ websocket, connected }) => {
    // TODO: do i really wanna be grabbin these 2 just to sidestep passing in an extra prop? idk
    const [user] = useContext(UserContext);
    const { id: deckId } = useParams();
    const [authorised, setAuthorised] = useState();
    const [mediaSharesEnabled, setMediaSharesEnabled] = useState();
    const [autoplayEnabled, setAutoplayEnabled] = useState();
    const [approvedOnly, setApprovedOnly] = useState();
    const [addMediaActive, setAddMediaActive] = useState(false);

    useEffect(() => {
        if (!websocket) return;
        const setting$ = websocket.message$.pipe(filter((message) => message.topic === 'settings'));
        const subscription = setting$.subscribe(({ body }) => {
            console.log(body);
            // NOTE: this one can be partial
            if (body.authorizedWithStreamlabs !== undefined) setAuthorised(body.authorizedWithStreamlabs);
            if (body.mediaSharesEnabled !== undefined) setMediaSharesEnabled(body.mediaSharesEnabled);
            if (body.autoplay !== undefined) setAutoplayEnabled(body.autoplay);
            if (body.approvedOnly !== undefined) setApprovedOnly(body.approvedOnly);
        });

        // now we're connected, fetch settings
        websocket.send({ type: 'getSettings' })
        
        return () => {
            console.log('setting$ unsubscribing from websocket.message$');
            subscription.unsubscribe();
        };
    }, [websocket]);

    const enableMediaShares = () => {
        websocket.send({ type: 'enableMediaShares' });
    };

    const disableMediaShares = () => {
        websocket.send({ type: 'disableMediaShares' });
    };

    const toggleAutoplay = () => {
        websocket.send({ type: 'setAutoplay', message: !autoplayEnabled });
    };

    const toggleApprovedOnly = () => {
        websocket.send({ type: 'setApprovedOnly', message: !approvedOnly});
    };

    const shufflePlaylist = () => {
        websocket.send({ type: 'shufflePlaylist' });
    };

    const clearPlaylists = () => {
        websocket.send({ type: 'clearPlaylists' });
    };

    const openAddMediaModal = () => {
        setAddMediaActive(true);
    };

    const closeAddMediaModal = () => {
        setAddMediaActive(false);
    };

    let controlPanelContent;
    if (!authorised) {
        controlPanelContent = (
            <span>
                <Icon icon={IconNames.WARNING_SIGN} iconSize={Icon.SIZE_LARGE} />
                &nbsp;
                { deckId === user.id ? 'You are' : 'Deck owner is' } not authorised with Streamlabs!
                &nbsp;
                <Icon icon={IconNames.WARNING_SIGN} iconSize={Icon.SIZE_LARGE} />
            </span>
        );
    } else {
        if (mediaSharesEnabled) {
            controlPanelContent = <Button fill onClick={disableMediaShares}>Disable Media Shares</Button>;
        } else {
            controlPanelContent = <Button fill onClick={enableMediaShares}>Enable Media Shares</Button>;
        }
    }
    return (
        <>
            <ControlsContainer>
                <h5 className="bp5-heading">Controls</h5>
                <ControlsInner connected={connected} >
                {
                    connected ? (
                        <>
                            { controlPanelContent }
                            <Button fill onClick={toggleAutoplay}>
                                {autoplayEnabled ? 'Dis' : 'En'}able Autoplay
                            </Button>
                            <Button fill onClick={toggleApprovedOnly}>
                                {approvedOnly ? 'Dis' : 'En'}able Play On Approval Only
                            </Button>
                            <Button fill onClick={openAddMediaModal}>
                                Add Media
                            </Button>
                            <Button fill onClick={shufflePlaylist}>
                                Shuffle Active Playlist
                            </Button>
                            <Button fill onClick={clearPlaylists}>
                                Clear Playlists
                            </Button>
                        </>
                    ) : <Spinner size={Spinner.SIZE_LARGE} />
                }
                </ControlsInner>
            </ControlsContainer>
            <NewMediaModal
                websocket={websocket}
                active={addMediaActive}
                onClose={closeAddMediaModal}
            />
        </>
    );
};

export default Controls;
