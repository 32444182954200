import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { timer } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { delayWhen, filter, retryWhen, share, tap } from 'rxjs/operators';

import DeckTitle from './DeckTitle';
import UserList from './UserList';
import Video from './Video';
import Controls from './Controls';
import Playlist from './Playlist';

const ContentContainer = styled.div`
    width: 1200px;
    margin: auto;
    margin-top: 20px;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    & > h1 {
        text-align: center;
    }
`;

const ControlPanel = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;

    & > * {
        margin-right: 20px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

// make this a class for fuck sake lmao
const Deck = () => {
    const { id: deckId } = useParams();
    // if this ain't set children should render spinners, so it's state
    // TODO: name this one better. cmon
    const [websocket, setWebsocket] = useState();
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        // grab that websocket babey
        // this could probably be better but for now it'll work
        const socketProtocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
        const websocketSubject = webSocket({
            url: `${socketProtocol}://${window.location.host}/api/websocket?deckId=${deckId}&accessType=user`,
            openObserver: {
                next: () => {
                    console.log('connected!');
                    setConnected(true);
                }
            },
            closeObserver: {
                next: () => {
                    console.log('disconnected!');
                    setConnected(false);
                }
            },
        });

        // TODO: figure out how delaywhen works for fuck sake
        const websocketOut = websocketSubject.pipe(
            retryWhen((errors) => errors.pipe(
                tap((e) => console.log(e)),
                delayWhen(() => timer(5000))
            )),
            share()
        );

        // listen to status updates in here
        const status$ = websocketOut.pipe(
            filter((message) => message.topic === 'status')
        );

        // don't make the socket available to page components until we get the socket id
        // NOTE: obviously this will change if i ever add more status messages,
        const subscription = status$.subscribe((message) => {
            setWebsocket({
                id: message.socketId,
                message$: websocketOut,
                send: (message) => websocketSubject.next(message),
            });
        });

        // respond to pings
        const ping$ = websocketOut.pipe(
            filter((message) => message.topic === 'ping')
        )

        const heartbeat = ping$.subscribe((message) => {
            // gotta do this manually since websocket's value isn't available in here
            websocketSubject.next({ type: 'pong' })
        })

        return () => {
            console.log('cleaning up client!!!')
            subscription.unsubscribe()
            heartbeat.unsubscribe()
            websocketSubject.complete()
        }
    }, [deckId]);

    return (
        <ContentContainer>
            <DeckTitle deckId={deckId} />
            <ControlPanel>
                <UserList websocket={websocket} connected={connected} />
                <Video websocket={websocket} />
                <Controls websocket={websocket} connected={connected} />
            </ControlPanel>
            <Playlist websocket={websocket} connected={connected} />
        </ContentContainer>
    );
};

export default Deck;
