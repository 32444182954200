import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import UserContext from './UserContext';

const PrivateRoute = ({ children, ...other }) => {
    const [user, _] = useContext(UserContext);

    return (
        <Route {...other} >
            {
                user ? children : <Redirect to="/login" />
            }
        </Route>
    );
};

export default PrivateRoute;
