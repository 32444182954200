import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UserContext from './UserContext';

const ContentContainer = styled.div`
    width: 500px;
    margin: auto;
    margin-top: 20px;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    & > h1 {
        text-align: center;
    }
`;

const ModeratedUser = ({ id, username }) => {
    return <Link to={`/deck/${id}`}>{username}</Link>;
};

const Home = () => {
    const [user] = useContext(UserContext);
    const [moderatedUsers, setModeratedUsers] = useState();

    useEffect(() => {
        const getModeratedUsers = async () => {
            // attempt to fetch user data
            const res = await fetch('/api/moderatesFor', {
                method: 'GET',
                credentials: 'include',
            });
            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setModeratedUsers(data.users);
            } else {
                setModeratedUsers(undefined);
            }
        };

        getModeratedUsers();
    }, []);

    return (
        <ContentContainer>
            <div>
                <h3 class="bp5-heading">Your Deck</h3>
                <Link to={`/deck/${user.id}`}>{user.username}</Link>
            </div>
            <div>
                <h3 class="bp5-heading">Decks You Moderate</h3>
                { moderatedUsers && moderatedUsers.length > 0 ? moderatedUsers.map((m) => (
                    <ModeratedUser
                        key={m.id}
                        id={m.id}
                        username={m.username}
                    />
                )) : <span>None yet!</span>
                }
            </div>
        </ContentContainer>
    );
};

export default Home;
