import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from "@blueprintjs/core";
import youtubePlayer from 'youtube-player';
import { fromEventPattern, timer } from 'rxjs';
import {
    distinctUntilChanged, filter, pairwise, scan, share, startWith, switchMap, withLatestFrom
} from 'rxjs/operators';

const Heading = styled.h5.attrs(props => ({
    className: 'bp5-heading'
}))`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const VideoContainer = styled.div`
    width: 480px;
`;

// sync within 3 seconds
// why 3 seconds? no reason lol it's totally arbitrary
// but this shouldn't call on every event or it'll probably cause some weird stuttering
const syncPlayTime = async (player, newTime) => {
    const currentTime = await player.getCurrentTime();
    if (Math.abs(currentTime - newTime) > 3) player.seekTo(newTime, true);
};

const syncVolume = async (player, newVolume) => {
    const currentVolume = await player.getVolume();
    if (newVolume !== currentVolume) player.setVolume(newVolume);
};

const Video = ({ websocket }) => {
    const [currentTrack, setCurrentTrack] = useState();
    const containerRef = useRef();

    useEffect(() => {
        if (!websocket) return;

        // initial state before any messages come in.
        // used as the start value for the accumulator, and the first thing emitted by stateChange$
        const initialState = {
            isLeader: false,
            currentTrack: null,
            currentTrackIsNew: false,
            leaderTime: 0,
            leaderIsPlaying: false,
            leaderVolume: 100,
        };

        // init player
        const opts = {
            width: 480,
            height: 270,
            playerVars: {
                autoplay: 1,
            }
        };
        const player = youtubePlayer(containerRef.current, opts);

        const onStateChange = (newState) => {
            console.log(newState);
            // if the current vid changed, load up the new vid
            if (newState.currentTrackIsNew) {
                setCurrentTrack(newState.currentTrack);
                if (newState.leaderIsPlaying) {
                    player.loadVideoById({
                        videoId: newState.currentTrack?.slug || null,
                        startSeconds: Math.max(newState.leaderTime, newState.currentTrack?.startTime),
                        endSeconds: newState.currentTrack?.startTime + (newState.currentTrack?.duration / 1000),
                    });
                } else {
                    player.cueVideoById({
                        videoId: newState.currentTrack?.slug || null,
                        startSeconds: Math.max(newState.leaderTime, newState.currentTrack?.startTime),
                        endSeconds: newState.currentTrack?.startTime + (newState.currentTrack?.duration / 1000),
                    });
                }
            }

            if (!newState.isLeader) {
                syncPlayTime(player, newState.leaderTime);
                syncVolume(player, newState.leaderVolume);
                if (newState.leaderIsPlaying) {
                    player.playVideo();
                } else {
                    player.pauseVideo();
                }
            }
        };

        const onTimeChange = ([currentTime, state]) => {
            // NOTE: we ignore time changes when the leader isn't playing
            // it's unfortunate as it breaks synced scrubbing but without this any new vids
            // coming in after the playlist has ended will resume at the time the last vid ended at
            if (state.isLeader && state.leaderIsPlaying) {
                websocket.send({
                    type: 'setTime',
                    message: currentTime
                });
            } else {
                // who are you running from?
                syncPlayTime(player, state.leaderTime);
            };
        };

        const onPlay = ([, state]) => {
            if (state.isLeader) {
                // if we're leader, tell everyone else to start playing
                websocket.send({ type: 'startPlayback' });
            } else {
                // knock it off!
                if (!state.leaderIsPlaying) player.pauseVideo();
            }
        };

        const onPause = ([, state]) => {
            if (state.isLeader) {
                // if we're leader, tell everyone else to pause
                websocket.send({ type: 'pausePlayback' });
            } else {
                // hey. its still vidz time
                if (state.leaderIsPlaying) player.playVideo();
            }
        };

        const onEnd = ([, state]) => {
            console.log('sending end playback signal');
            if (state.isLeader) websocket.send({ type: 'endPlayback' });
        };

        const onVolumeChange = ([currentVolume, state]) => {
            if (state.isLeader) {
                websocket.send({
                    type: 'setVolume',
                    message: currentVolume
                });
            } else {
                // crank it up!!!!
                syncVolume(player, state.leaderVolume);
            };
        };

        // rig up all these functions to observers...
        // observer for incoming messages

        // take the current state and a message body, return the new state
        // NOTE: if currentTrack is undefined, it simply wasn't passed in
        // if it's null, there is no current track. hate to abuse undefined/null like that but
        // it's either that or "false", which is honestly even more confusing
        const determineNewState = (socketId) => (
            prevState, { body: { leaderId, currentTrack, time, playing, volume } }
        ) => {
            console.log({ leaderId, currentTrack, time, playing });
            const newState = { ...prevState };
            if (leaderId) newState.isLeader = leaderId === socketId;
            if (currentTrack !== undefined) newState.currentTrack = currentTrack;
            newState.currentTrackIsNew =
                currentTrack !== undefined && currentTrack?._id !== prevState.currentTrack?._id;
            if (time !== undefined) newState.leaderTime = time;
            if (playing !== undefined) newState.leaderIsPlaying = playing;
            if (volume !== undefined) newState.leaderVolume = volume;
            return newState;
        };
        const determineNewStateForUser = determineNewState(websocket.id);
        const stateChange$ = websocket.message$.pipe(
            filter((message) => message.topic === 'sync'),
            scan(determineNewStateForUser, initialState),
            startWith(initialState),
            share(),
        );
        const stateChangeSub = stateChange$.subscribe(onStateChange);

        // observer for polling playback position
        const playTimeChange$ = timer(0, 1000).pipe(
            switchMap(() => player.getCurrentTime()),
            filter((x) => x !== undefined),
            distinctUntilChanged(),
            withLatestFrom(stateChange$)
        );
        const playTimeChangeSub = playTimeChange$.subscribe(onTimeChange);

        // observer for polling volume
        const volumeChange$ = timer(0, 1000).pipe(
            switchMap(() => player.getVolume()),
            filter((x) => x !== undefined),
            distinctUntilChanged(),
            withLatestFrom(stateChange$)
        );
        const volumeChangeSub = volumeChange$.subscribe(onVolumeChange);

        // chaining observables to separate out events
        // youtube-player uses a 7 year old event handling library for whatever reason
        // so we gotta do it manually. we also gotta do some wacky shit to pass the listener object,
        // NOTE: this will not allow multiple connections to the same event, but since it's just
        // used to make an observable it shouldn't matter... i think?
        const wrapEventHandlers = (emitter) => (name) => {
            let listener;
            return [
                (handler) => listener = emitter.on(name, handler),
                () => emitter.off(listener)
            ];
        };
        const wrapPlayerEventHandlers = wrapEventHandlers(player);
        const playerState$ = fromEventPattern(...wrapPlayerEventHandlers('stateChange')).pipe(share());
        const play$ = playerState$.pipe(
            filter(({ data }) => data === 1),
            withLatestFrom(stateChange$)
        );
        const pause$ = playerState$.pipe(
            filter(({ data }) => data === 2),
            withLatestFrom(stateChange$)
        );
        // for some fucking reason the youtube iframe api fires a second end event
        // if a track doesn't run to completion (ie. only part of the track was donated for)
        // this workaround should stop end$ firing if the track wasn't actually playing when it ended,
        const end$ = playerState$.pipe(
            pairwise(),
            filter(([{ data: prevData }, { data: currentData }]) => prevData === 1 && currentData === 0),
            withLatestFrom(stateChange$)
        );

        const playSub = play$.subscribe(onPlay);
        const pauseSub = pause$.subscribe(onPause);
        const endSub = end$.subscribe(onEnd);

        // everything's hooked up now, fetch sync data
        websocket.send({ type: 'getSync' })

        return () => {
            console.log('stateChange$ unsubbing from websocket.message$');
            stateChangeSub.unsubscribe();
            console.log('unsubbing from playTimeChange$');
            playTimeChangeSub.unsubscribe();
            console.log('unsubbing from volumeChange$');
            volumeChangeSub.unsubscribe();
            console.log('unsubbing from play$');
            playSub.unsubscribe();
            console.log('unsubbing from pause$');
            pauseSub.unsubscribe();
            console.log('unsubbing from end$');
            endSub.unsubscribe();
        };
    }, [websocket]);

    return (
        <VideoContainer>
            <Heading>
                <Text ellipsize>
                    Current Track: {currentTrack ? currentTrack.title : 'None'}
                </Text>
            </Heading>
            <div ref={containerRef} />
        </VideoContainer>
    );
};

export default Video;
