import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Card, TagInput, Overlay } from '@blueprintjs/core';

const StyledCard = styled(Card).attrs(props => ({
    className: 'bp5-dark'
}))`
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    min-width: 500px;
    width: 500px;
`;

const StyledTagInput = styled(TagInput)`
    margin-top: 1em;
`;

const StyledButton = styled(Button)`
    margin-top: 1em;
`;

const TagOverlay = ({ show, title, mediaId, tags, onSubmit, onClose }) => {
    const [internalTags, setInternalTags] = useState();

    useEffect(() => {
        setInternalTags(tags || []);
    }, [tags]);

    const handleTagChange = (values) => {
        setInternalTags(values);
    };

    return (
        <Overlay isOpen={show} onClose={onClose}>
            <StyledCard>
                <h3 className="bp5-heading">Edit Tags</h3>
                {title}
                { internalTags && (
                <StyledTagInput
                    fill
                    values={internalTags}
                    placeholder="New Tag"
                    onChange={(e) => handleTagChange(e)}
                />
                )}
                <StyledButton onClick={() => onSubmit(mediaId, internalTags)}>Submit</StyledButton>
            </StyledCard>
        </Overlay>
    );
};

export default TagOverlay;
