import { useState, useContext } from 'react';
import styled from 'styled-components';
import { InputGroup, Button } from '@blueprintjs/core';

import UserContext from './UserContext';

const LoginContainer = styled.div`
    width: 240px;
    margin: auto;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    & > h1 {
        text-align: center;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
`;

const Login = () => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [_, setUser] = useContext(UserContext);

    // TODO: should probably try catch this one
    const loginUser = async (credentials) => {
        const res = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
            // NOTE: this is currently mandatory cos we're technically going cross-origin
            // but in production i'll be doing bullshit with nginx so i probably won't need it?
            // worry about it later.
            credentials: 'include',
        });
        const data = await res.json();
        console.log(data);
        if (data.user) {
            setUser(data.user);
        }
    };

    return (
        <LoginContainer>
            <h1 class="bp5-heading">Log In</h1>
            <InputGroup
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
            />
            <InputGroup
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <StyledButton
                text="Submit"
                onClick={() => loginUser({ username, password })}
            />
        </LoginContainer>
    );
};

export default Login;
