import { useEffect, useState } from 'react';

const Streamlabs = () => {
    const [authorised, setAuthorised] = useState();
    const [authUrl, setAuthUrl] = useState();

    useEffect(() => {
        const getAuthStatus = async () => {
            // attempt to fetch user data
            const res = await fetch('/api/auth/status', {
                method: 'GET',
                credentials: 'include',
            });
            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setAuthorised(data.authorised);
                setAuthUrl(data.authUrl);
            } else {
                setAuthorised(undefined);
                setAuthUrl(undefined);
            }
        };

        getAuthStatus();
    }, []);

    return (
        <div>
            <h3 class="bp5-heading">Streamlabs</h3>
            <div>{authorised ? 'Authorised with Streamlabs!' : 'Not authorised with Streamlabs'}</div>
            <div>{authUrl && <a href={authUrl}>{`${authorised ? 'Rea' : 'A'}uthorise with Streamlabs`}</a>}</div>
        </div>
    );
};

export default Streamlabs;
