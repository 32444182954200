import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card } from '@blueprintjs/core';

const HeadingLine = styled.div`
    display: flex;
    align-items: flex-end;
`;

const Heading = styled.h3.attrs((props) => ({
    className: 'bp5-heading',
}))`
    flex-grow: 1;
    text-align: left;
`;

const StatusMessage = styled.span`
    margin: 0 0 10px;
    text-align: right;
`;

const MediaItem = styled.div`
    display: flex;
`;

const MediaTitle = styled.span`
    flex-grow: 1;
    line-height: 30px;
`;

const BannedMediaItem = ({ title, onClickUnban }) => {
    return (
        <MediaItem>
            <MediaTitle>{title}</MediaTitle>
            <Button text="Unban" onClick={onClickUnban} />
        </MediaItem>
    );
};

const BannedMedia = () => {
    const [bannedMedia, setBannedMedia] = useState();
    const [status, setStatus] = useState();

    useEffect(() => {
        const getBannedMedia = async () => {
            // attempt to fetch user data
            const res = await fetch('/api/bannedMedia', {
                method: 'GET',
                credentials: 'include',
            });
            if (res.status === 200) {
                const data = await res.json();
                console.log(data);
                setBannedMedia(data.bannedMedia);
            } else {
                setBannedMedia(undefined);
            }
        };

        getBannedMedia();
    }, []);

    const handleUnban = (id) => async () => {
        const res = await fetch('/api/unbanMedia', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id }),
            credentials: 'include',
        });
        const data = await res.json();
        console.log(data);
        if (data.message) {
            setStatus(data.message);
        }
        if (res.status === 200) {
            const newBannedMedia = JSON.parse(JSON.stringify(bannedMedia));
            setBannedMedia(newBannedMedia.filter((u) => u.id !== id));
        }
    };

    return (
        <div>
            <HeadingLine>
                <Heading>Banned Media</Heading>
                <StatusMessage>{status}</StatusMessage>
            </HeadingLine>
            <Card>
                {
                    bannedMedia && bannedMedia.map((m) => (
                        <BannedMediaItem
                            title={m.title}
                            onClickUnban={handleUnban(m.id)}
                        />
                    ))
                }
            </Card>
        </div>
    );
};

export default BannedMedia;
