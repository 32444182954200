import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Classes } from '@blueprintjs/core';

import PrivateRoute from './PrivateRoute';
import UserContext from './UserContext';

import Login from './Login';
import Register from './pages/register/Register';
import Navigation from './Navigation';
import Home from './Home';
import Deck from './pages/deck/Deck';
import NowPlaying from './pages/deck/NowPlaying';
import Embed from './pages/deck/Embed';
import Settings from './pages/settings/Settings';

const AppContainer = styled.div.attrs(props => ({
    className: Classes.DARK,
}))`
    height: 100%;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const App = () => {
    // TODO: this could be better.
    const [user, setUser] = useState();

    useEffect(() => {
        // TODO: could i just set this as the default context value? hmmm
        // actually it's async so the answer is no lol
        const fetchUserData = async () => {
            // attempt to fetch user data
            const res = await fetch('/api/user', {
                method: 'GET',
                credentials: 'include',
            });
            if (res.status === 200) {
                // if it works we're fucking logged in babey
                const data = await res.json();
                console.log(data);
                setUser(data.user);
            } else {
                setUser(false);
            }
        };

        fetchUserData();
    }, []);

    if (user === undefined) return null;

    return (
        <DndProvider backend={HTML5Backend}>
            <UserContext.Provider value={[user, setUser]}>
                <BrowserRouter>
                    <AppContainer>
                        <Navigation />
                        <ContentContainer>
                            <PrivateRoute exact path="/" >
                                <Redirect to="/home" />
                            </PrivateRoute>
                            <Route exact path="/login">
                                { user && <Redirect to="/home" /> }
                                <Login />
                            </Route>
                            <Route exact path="/register">
                                { user && <Redirect to="/home" /> }
                                <Register />
                            </Route>
                            <PrivateRoute exact path="/home" >
                                <Home />
                            </PrivateRoute>
                            <PrivateRoute path="/deck/:id" >
                                <Deck />
                            </PrivateRoute>
                            <Route path="/embed/:id" >
                                <Embed />
                            </Route>
                            <Route path="/nowplaying/:id" >
                                <NowPlaying />
                            </Route>
                            <PrivateRoute exact path="/settings" >
                                <Settings />
                            </PrivateRoute>
                        </ContentContainer>
                    </AppContainer>
                </BrowserRouter>
            </UserContext.Provider>
        </DndProvider>
    );
};

export default App;
