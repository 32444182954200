import styled from 'styled-components';

import Streamlabs from './Streamlabs';
import Moderators from './Moderators';
import BannedMedia from './BannedMedia';

const ContentContainer = styled.div`
    width: 500px;
    margin: auto;
    margin-top: 20px;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    & > h1 {
        text-align: center;
    }
`;

const Settings = () => {
    return (
        <ContentContainer>
            <Streamlabs />
            <Moderators />
            <BannedMedia />
        </ContentContainer>
    );
};

export default Settings;
