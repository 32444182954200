import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { timer } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import {
    delayWhen, filter, retryWhen, scan, share, startWith, tap
} from 'rxjs/operators';

// page for showing just the current track title

const GlobalStyle = createGlobalStyle`
    html, body {
        height: unset;
    }

    body {
        padding-top: 0;
        background-color: unset;
    }
`

const TrackTitle = styled.span`
    color: black;
`

const NowPlaying = () => {
    const { id: deckId } = useParams();
    // if this ain't set children should render spinners, so it's state
    // TODO: name this one better. cmon
    const [websocket, setWebsocket] = useState();
    const [currentTrack, setCurrentTrack] = useState();

    useEffect(() => {
        // grab that websocket babey
        // this could probably be better but for now it'll work
        const socketProtocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
        const websocketSubject = webSocket({
            url: `${socketProtocol}://${window.location.host}/api/websocket?deckId=${deckId}`,
            openObserver: {
                next: () => {
                    console.log('connected!');
                }
            },
            closeObserver: {
                next: () => {
                    console.log('disconnected!');
                }
            },
        });

        // TODO: figure out how delaywhen works for fuck sake
        const websocketOut = websocketSubject.pipe(
            retryWhen((errors) => errors.pipe(
                tap((e) => console.log(e)),
                delayWhen(() => timer(5000))
            )),
            share()
        );

        // listen to status updates in here
        const status$ = websocketOut.pipe(
            filter((message) => message.topic === 'status')
        );

        // don't make the socket available to page components until we get the socket id
        // NOTE: obviously this will change if i ever add more status messages,
        const subscription = status$.subscribe((message) => {
            setWebsocket({
                id: message.socketId,
                message$: websocketOut,
                send: (message) => websocketSubject.next(message),
            })
        })

        // respond to pings
        const ping$ = websocketOut.pipe(
            filter((message) => message.topic === 'ping')
        )

        const heartbeat = ping$.subscribe((message) => {
            // gotta do this manually since websocket's value isn't available in here
            websocketSubject.next({ type: 'pong' })
        })

        // fetch sync data
        websocketSubject.next({ type: 'getSync' })

        return () => {
            console.log('cleaning up client!!!')
            subscription.unsubscribe()
            heartbeat.unsubscribe()
            websocketSubject.complete()
        };
    }, [deckId]);

    useEffect(() => {
        if (!websocket) return;

        // initial state before any messages come in.
        // used as the start value for the accumulator, and the first thing emitted by stateChange$
        const initialState = {
            currentTrack: null,
            currentTrackIsNew: false,
        };

        const onStateChange = (newState) => {
            console.log(newState);
            // if the current vid changed, load up the new vid
            if (newState.currentTrackIsNew) {
                setCurrentTrack(newState.currentTrack);
            }
        };

        // rig up all these functions to observers...
        // observer for incoming messages

        // take the current state and a message body, return the new state
        // NOTE: if currentTrack is undefined, it simply wasn't passed in
        // if it's null, there is no current track. hate to abuse undefined/null like that but
        // it's either that or "false", which is honestly even more confusing
        const determineNewState = (socketId) => (
            prevState, { body: { currentTrack } }
        ) => {
            console.log({ currentTrack });
            const newState = { ...prevState };
            if (currentTrack !== undefined) newState.currentTrack = currentTrack;
            newState.currentTrackIsNew =
                currentTrack !== undefined && currentTrack?._id !== prevState.currentTrack?._id;
            return newState;
        };
        const determineNewStateForUser = determineNewState(websocket.id);
        const stateChange$ = websocket.message$.pipe(
            filter((message) => message.topic === 'sync'),
            scan(determineNewStateForUser, initialState),
            startWith(initialState),
            share(),
        );
        const stateChangeSub = stateChange$.subscribe(onStateChange);

        return () => {
            console.log('stateChange$ unsubbing from websocket.message$');
            stateChangeSub.unsubscribe();
        };
    }, [websocket]);

    return (
        <>
            <GlobalStyle />
            <TrackTitle id="now-playing">Current Track: {currentTrack ? currentTrack.title : 'None'}</TrackTitle>
        </>
    );
};

export default NowPlaying;
