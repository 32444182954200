import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { filter } from 'rxjs/operators';

const ModListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
`;

const UserListInner = styled.div.attrs(props => ({
    className: 'bp5-card'
}))`
    max-height: 100%;
    height: 270px;
    ${props => !props.connected && 'display: flex; justify-content: center; align-items: center;'}
`;

const FakeButton = styled.div`
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 5px;
`;

const StyledButton = styled(Button)`
    margin-right: 5px;
`;

const UserRow = ({ username, leader, onClick }) => {
    return (
        <div>
            { leader
                ? (
                    <FakeButton>
                        <Icon icon={IconNames.STAR} />
                    </FakeButton>
                ) : (
                    <StyledButton 
                        minimal
                        icon={IconNames.STAR_EMPTY}
                        onClick={onClick}
                    />
                )
            }
            {username}
        </div>
    );
};

const UserList = ({ websocket, connected }) => {
    // TODO: coooould use a better name....
    const [userList, setUserList] = useState([]);
    const [leaderId, setLeaderId] = useState();

    useEffect(() => {
        if (!websocket) return;
        const user$ = websocket.message$.pipe(filter((message) => message.topic === 'users'));
        const subscription = user$.subscribe(({ body }) => {
            console.log(body);
            if (body.userList) {
                // TODO: this works but it's a bit shit,
                const dupes = {};
                const newUserList = body.userList.map((user) => {
                    if (dupes[user.username]) {
                        dupes[user.username]++;
                    } else {
                        dupes[user.username] = 1;
                    }
                    return {
                        ...user,
                        username: dupes[user.username] === 1 ? user.username : `${user.username} (${dupes[user.username]})`
                    };
                });
                setUserList(newUserList);
            }
            setLeaderId(body.leaderId);
        });

        // now we're connected, fetch the users
        websocket.send({ type: 'getUsers' })

        return () => {
            console.log('user list unsubbed from websocketsubject');
            subscription.unsubscribe();
        }
    }, [websocket])

    const handleClick = (id) => () => {
        console.log(`attempting to set ${id} as new leader`);
        websocket.send({
            type: 'setLeader',
            message: id,
        });
    };

    console.log(`leaderId: ${leaderId}`);
    return (
        <ModListContainer>
            <h5 className="bp5-heading">Active Users</h5>
            <UserListInner connected={connected} >
                {
                    connected ? userList.map((u) => (
                        <UserRow
                            key={u.id}
                            username={u.username}
                            leader={u.id === leaderId}
                            onClick={handleClick(u.id)}
                        />
                    )) : <Spinner size={Spinner.SIZE_LARGE} />
                }
            </UserListInner>
        </ModListContainer>
    );
};

export default UserList;
