import { useState } from 'react';
import styled from 'styled-components';
import { Button, NonIdealState, Overlay } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import TextField from '../../common/fields';
import Card from '../../common/Card';

const RegistrationSchema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'Must be at least 4 characters in length')
        .max(32, 'Must be less than 32 characters in length')
        .required('Required'),
    password: Yup.string()
        .min(8, 'Must be at least 8 characters in length')
        .required('Required'),
    passwordConfirmation: Yup.string()
        .test('passwordsMatch', 'Must match password', function (value) {
            return this.parent.password === value;
        })
        .required('Required'),
    registrationKey: Yup.string()
        .required('Required'),
});

const StyledForm = styled(Form)`
    width: 100%;

    & > * {
        margin-bottom: 10px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;

const RegisterContainer = styled.div`
    width: 240px;
    margin: auto;

    & > h1 {
        text-align: center;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;

    &:disabled {
        opacity: 50%;
        cursor: default;
    }
`;

// as doesn't work with non-styled components so here's this
const LinkButton = (props) => {
    return (
        <Link
            className='bp5-button'
            {...props}
        />
    );
};

const Register = () => {
    const [status, setStatus] = useState();

    const handleSubmit = async ({ username, password, registrationKey }) => {
        const res = await fetch('/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username, password, registrationKey
            }),
            credentials: 'include',
        });
        const data = await res.json();
        console.log(data);
        setStatus({ success: res.status === 200, message: data.message });
    };

    const dismissButton = status?.success ? (
        <LinkButton to="/login">
            Proceed to Log In
        </LinkButton>
    ) : (
        <Button
            text={'Dismiss'}
            onClick={() => setStatus(undefined)}
        />
    );

    return (
        <>
            <RegisterContainer>
                <h1 className="bp5-heading">Register</h1>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        passwordConfirmation: '',
                        registrationKey: '',
                    }}
                    validationSchema={RegistrationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ dirty, isValid }) => (
                        <StyledForm>
                            <TextField
                                name="username"
                                placeholder="Username"
                            />
                            <TextField
                                name="password"
                                placeholder="New Password"
                                type="password"
                            />
                            <TextField
                                name="passwordConfirmation"
                                placeholder="Re-enter Password"
                                type="password"
                            />
                            <TextField
                                name="registrationKey"
                                placeholder="Registration Key"
                                type="password"
                            />
                            <StyledButton
                                text="Submit"
                                type="submit"
                                disabled={!isValid || !dirty}
                            />
                        </StyledForm>
                    )}
                </Formik>
            </RegisterContainer>
            <Overlay isOpen={!!status}>
                <Card>
                    <NonIdealState
                        icon={status?.success ? IconNames.TICK_CIRCLE : IconNames.WARNING_SIGN}
                        title={status?.success ? 'Registration successful!' : 'An error occurred!'}
                        description={status?.message}
                        action={dismissButton}
                    />
                </Card>
            </Overlay>
        </>
    );
};

export default Register;
